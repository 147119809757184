import WasmController from "react-lib/frameworks/WasmController";

import { PDFDocument } from "pdf-lib";
import moment from "moment";

// APIs
import MedicationErrorCauseDetailList from "issara-sdk/apis/MedicationErrorCauseDetailList_apps_TPD";
import MedicationErrorCauseList from "issara-sdk/apis/MedicationErrorCauseList_apps_TPD";
import MedicationErrorDetail from "issara-sdk/apis/MedicationErrorDetail_apps_TPD";
import MedicationErrorDetailM from "issara-sdk/apis/MedicationErrorDetail_apps_TPDM";
import MedicationErrorList from "issara-sdk/apis/MedicationErrorList_apps_TPD";
import MedicationErrorTypeDetailList from "issara-sdk/apis/MedicationErrorTypeDetailList_apps_TPD";
import MedicationErrorTypeList from "issara-sdk/apis/MedicationErrorTypeList_apps_TPD";
import UserList from "issara-sdk/apis/UserList_users";

import MedicationErrorSerializerI from "issara-sdk/types/MedicationErrorSerializer_apps_TPD";

// Form
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { base64toBlob, mapOptions } from "react-lib/apps/HISV3/common/CommonInterface";

import FormFeedbackReport from "../FormFeedbackReport";

// Utils
import { adToBe, beToAd, formatDate, formatDatetime } from "react-lib/utils/dateUtils";

// DrugOrderQueueList_apps_TPD
export type State = {
  // CommonInterface
  django?: any;
  selectedPatient?: any;
  selectedDrugOrderWorking?: any;
  masterOptions?: any;
  buttonLoadCheck?: any;
  searchedItemListWithKey?: any;
  MedErrorListSequence?: {
    // Medication Error List
    sequenceIndex?: NextIndexType | "START" | null;
    medErrorList?: any[];
    filterMedError?: Partial<{
      status: number[];
      division: string;
      user: string;
      start_date: string;
      end_date: string;
      type: string[];
      stakeholder: number;
      stakeholder_name: string;
    }>;
    medErrorWorking?: Record<string, any>;
    medErrorStatistics?: Partial<{
      open: true;
      start_date: string;
      end_date: string;
      items: any[];
    }>;
  } | null;
};

type MedicationErrorSerializer = {
  cause: string | string[];
  type_detail: string | string[];
  cause_detail: string | string[];
  editor_by: string;
  corrective: number;
} & Omit<MedicationErrorSerializerI, "cause" | "cause_detail" | "type_detail">;

// Sequence
type NextIndexType = "EditMedError" | "SaveMedError" | "SearchMedError";

type SeqState = {
  sequence: "MedErrorList";
  restart?: boolean;
  clear?: boolean;
  id?: number;
  isOrderItem?: boolean;
  byCorrective?: boolean;
  nextIndex?: NextIndexType;
  card?: string;
  noInit?: boolean;
};

// Handle Action
type ActionType =
  // Start
  | { action: "change"; data: any }
  | { action: "search"; card: string; byCorrective?: boolean }
  | { action: "statistics_search" }
  | {
      action: "edit";
      data: Partial<MedicationErrorSerializer> | null;
      isActionComplete?: boolean;
      callback?: (status: string, message: any) => any;
    }
  | {
      action: "delete";
      data: Record<string, any>;
      callback?: (status: string, message: any) => any;
    }
  | { action: "export_excel" }
  | { action: "export_excel_statistics" }
  | { action: "print"; list: any[]; quarterDetail?: any; card?: string }
  | { action: "init" }
  // Edit
  | { action: "change_type"; type: string[] }
  | { action: "change_cause"; cause: string[] }
  | {
      action: "update";
      detail: Record<string, any>;
      typeList?: any[];
      typeDetailOptions?: any[];
      causeList?: any[];
      user?: number;
      causeDetailOptions?: any[];
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => any;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  MedErrorListSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: Record<string, unknown> }
  | { message: "GetMasterData"; params: Record<string, unknown> };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

export const USER_SEARCH_MEW = "User_MEW";
export const DIVISION_SEARCH_MEWS = "Division_MEWS";
export const USER_SEARCH_MEWS = "User_MEWS";

export const MED_ERROR_CATEGORY = [
  "A = เหตุการณ์ที่มีศักภาพที่จะเกิดความผิดพลาด",
  "B = เกิดข้อผิดพลาดแล้ว แต่ไม่ถึงผู้ป่วย",
  "C = เกิดข้อผิดพลาด ถึงผู้ป่วย แต่ไม่มีอาการ",
  "D = เกิดข้อผิดพลาด ถึงผู้ป่วย อยู่ในช่วงของการเฝ้าระวังว่าเกิดอันตรายต่อจนต้องการ Intervention เพื่อแก้ปัญหา (E) หรือไม่เกิดอันตรายต่อผู้ป่วย (C)",
  "E = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยชั่วคราว และต้องการ intervention เพื่อแก้ปัญหา",
  "F = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยชั่วคราว และต้องการ intervention เพื่อแก้ปัญหาจนทำให้ระยะเวลาที่อยู่ รพ.ยาวนานขึ้นกว่าเดิม",
  "G = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยอย่างถาวร",
  "H = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตรายต่อผู้ป่วยอยู่ในระดับที่รุนแรงถึงต้องช่วยชีวิต",
  "I = เกิดข้อผิดพลาด ถึงผุ้ป่วย เกิดอันตายจนทำให้ผู้ป่วยเสียชีวิต",
];

type Handler<P = any, R = any> = (controller: WasmController<State, Event, Data>, params: P) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const Start: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  if (!state.MedErrorListSequence) {
    return;
  }

  Next(controller, params);
};

/* ------------------------------------------------------ */

/*                          Next                          */

/* ------------------------------------------------------ */
const Next: Handler<SeqState> = (controller, params) => {
  const state = controller.getState();

  const nextIndex = params.nextIndex;

  if (!nextIndex) {
    return;
  }

  controller.setState(
    {
      MedErrorListSequence: { ...state.MedErrorListSequence, sequenceIndex: nextIndex },
    },
    () => {
      const init = {
        SearchMedError,
        EditMedError,
        SaveMedError,
      }[nextIndex];

      if (!params.noInit) {
        init(controller, { ...params, action: "init", nextIndex: undefined });
      }
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const SearchMedError: Handler<
  ActionType & Pick<SeqState, "nextIndex" | "sequence">
> = async (controller, params) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    init: HandleInitSearch,
    search: HandleSearch,
    change: HandleChange,
    statistics_search: HandleStatisticsSearch,
    delete: HandleDelete,
    export_excel: HandleExportExcel,
    export_excel_statistics: HandleExportExcelStatistics,
    print: HandlePrint,
  };

  const action = params.action;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

export const EditMedError: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    init: HandleInitEdit,
    change_type: HandleChangeType,
    change_cause: HandleChangeCause,
    update: HandleUpdateValue,
    edit: () =>
      Next(controller, {
        ...params,
        sequence: "MedErrorList",
        nextIndex: "SaveMedError",
      }),
  };

  const action = params.action;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

export const SaveMedError: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  if (params.action === "init") {
    HandleSave(controller, params as any);
  }
};

/* ------------------------------------------------------ */

/*                 Handle SearchMedError;                 */

/* ------------------------------------------------------ */
const HandleInitSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  const status = state.masterOptions?.medErrorStatus;

  if (!status) {
    // Master data
    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["medErrorStatus", {}],
          ["divisionDrug", {}],
          ["medErrorType", {}],
        ],
      },
    });
  }

  controller.setState(
    {
      MedErrorListSequence: {
        ...state.MedErrorListSequence,
        sequenceIndex: "SearchMedError",
        filterMedError: {
          division: "ALL",
          start_date: formatDate(moment().add(-6, "days")),
          end_date: formatDate(moment()),
          ...params.filter,
          ...state.MedErrorListSequence?.filterMedError,
        },
      },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: {
          ...params,
          action: "search",
          byCorrective: params.byCorrective,
        },
      });
    }
  );
};

const HandleChange: Handler<Params<"change">> = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    MedErrorListSequence: {
      ...state.MedErrorListSequence,
      filterMedError: { ...params.data },
    },
  });
};

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const medError = await GetMedicationErrorList(controller, {
    ...params,
    filter: state.MedErrorListSequence?.filterMedError,
  });

  controller.setState({
    MedErrorListSequence: {
      ...state.MedErrorListSequence,
      medErrorList: medError[0]?.items || [],
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleStatisticsSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const medError = await GetMedicationErrorList(controller, {
    ...params,
    filter: state.MedErrorListSequence?.medErrorStatistics,
  });

  const items: any[] = medError[0]?.items || [];

  const result: any[] = [];

  for (const item of items) {
    const index = result.findIndex(
      (acc) =>
        acc.stakeholder === item.stakeholder &&
        acc.type === item.type &&
        acc.drug_code === item.drug_code &&
        acc.drug_name === item.drug_name
    );

    if (index === -1) {
      result.push({ ...item, qty: 1 });
    } else {
      result[index].qty += 1;
    }
  }

  controller.setState({
    MedErrorListSequence: {
      ...state.MedErrorListSequence,
      medErrorStatistics: {
        ...state.MedErrorListSequence?.medErrorStatistics,
        items: result,
      },
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleDelete: Handler<Params<"delete">> = async (controller, params) => {
  const [_, error] = await MedicationErrorDetailM.delete({
    apiToken: controller.apiToken,
    pk: params.data.id,
  });

  params.callback?.(error ? "error" : "success", error);
};

const HandleExportExcel: Handler = async (controller, params) => {
  const state = controller.getState();

  const medErrorList = state.MedErrorListSequence?.medErrorList || [];

  const XLSX = await import("xlsx");

  const workbook = XLSX.utils.book_new();
  workbook.SheetNames.push("Report");

  const sheet_data = [
    [
      "ลำดับ",
      "วัน-เวลาที่รายงาน",
      "รายการที่คลาดเคลื่อน",
      "ประเภท",
      "ลักษณะ",
      "สาเหตุ",
      "ผู้ที่ทำให้เกิดความผิดพลาด",
      "สถานะ",
    ],
    ...medErrorList.map((item, index) => [
      index + 1,
      formatDatetime(item.created),
      `[${item.drug_code || ""}][${item.drug_name || ""}]`,
      item.type_name,
      item.type_detail,
      item.cause_detail,
      item.stakeholder_name,
      item.status,
    ]),
  ];

  const sheet = XLSX.utils.aoa_to_sheet(sheet_data);

  workbook.Sheets["Report"] = sheet;

  const output = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

  const url = URL.createObjectURL(
    new Blob([output], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );

  const link = document.createElement("a");
  link.href = url;
  link.download = "report.xlsx";
  link.click();
  link.remove();
};

const HandleExportExcelStatistics: Handler = async (controller, params) => {
  const state = controller.getState();

  const items = state.MedErrorListSequence?.medErrorStatistics?.items || [];

  const XLSX = await import("xlsx");

  const workbook = XLSX.utils.book_new();
  workbook.SheetNames.push("Report");

  const sheet_data = [
    ["ลำดับ", "ผู้ที่ทำให้เกิดความผิดพลาด", "ประเภท", "รายการที่คลาดเคลื่อน", "จำนวน"],
    ...items.map((item, index) => [
      index + 1,
      item.stakeholder_name,
      item.type_name,
      `[${item.drug_code || ""}][${item.drug_name || ""}]`,
      item.qty,
    ]),
  ];

  const sheet = XLSX.utils.aoa_to_sheet(sheet_data);

  workbook.Sheets["Report"] = sheet;

  const output = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

  const url = URL.createObjectURL(
    new Blob([output], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );

  const link = document.createElement("a");
  link.href = url;
  link.download = "report.xlsx";
  link.click();
  link.remove();
};

/* ------------------------------------------------------ */

/*                   Handle EditMedError                  */

/* ------------------------------------------------------ */
const HandleInitEdit: Handler = async (controller, params) => {
  // Get Master
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["medErrorRiskType", {}], //Risk
        ["medErrorCategory", {}], //Category
      ],
    },
  });

  const { cause, causeDetailOptions, detail, type, typeDetail, user } =
    await GetMasterMedErrorDetail(controller, params);

  const state = controller.getState();

  const medErrorDetail = {
    ...detail,
    type_detail: typeof detail.type_detail === "string" ? detail.type_detail.split(",") : [],
    cause: typeof detail.cause === "string" ? detail.cause.split(",") : [],
    cause_detail: typeof detail.cause_detail === "string" ? detail.cause_detail.split(",") : [],
    risk_date: detail.risk_date ? adToBe(detail.risk_date, "YYYY-MM-DD") : "",
    order_division: detail.order_division_name || state.selectedDrugOrderWorking?.order_div || "",
    patient_name: detail.patient_name || state.selectedPatient?.full_name || "",
  };

  HandleUpdateValue(controller, {
    action: "update",
    detail: medErrorDetail,
    typeList: type,
    typeDetailOptions: mapOptions(typeDetail, "name"),
    causeList: cause,
    user: user[0] || {},
    readOnly: params.readOnly,
    causeDetailOptions,
  });
};

const HandleChangeType: Handler<Params<"change_type">> = async (controller, params) => {
  const [typeDetailList] = await MedicationErrorTypeDetailList.list({
    apiToken: controller.apiToken,
    params: { error_type: params.type },
  }); //ลักษณะ

  controller.handleEvent({
    message: "RunSequence",
    params: {
      ...params,
      action: "update",
      typeDetailOptions: mapOptions(typeDetailList?.items || [], "name"),
    },
  });
};

const HandleChangeCause: Handler<Params<"change_cause">> = async (controller, params) => {
  const state = controller.getState();

  const medErrorWorking = state.MedErrorListSequence?.medErrorWorking;
  const causeList: any[] = medErrorWorking?.causeList || [];

  const causeDetailOptions = await GetCauseDetailOptions(controller, {
    cause: params.cause,
    causeList,
    options: medErrorWorking?.causeDetailOptions || [],
  });

  controller.handleEvent({
    message: "RunSequence",
    params: { ...params, action: "update", causeDetailOptions },
  });
};

const HandleUpdateValue: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    MedErrorListSequence: {
      ...state.MedErrorListSequence,
      medErrorWorking: {
        ...state.MedErrorListSequence?.medErrorWorking,
        ...params,
      },
    },
  });
};

const HandlePrint: Handler<Params<"print">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const medErrorCategory = state.masterOptions.medErrorCategory;

  if (!medErrorCategory) {
    // Get Master
    await controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["medErrorRiskType", {}], //Risk
          ["medErrorCategory", {}], //Category
        ],
      },
    });
  }

  state = controller.getState();

  const pdfMake = await getPdfMake();

  const createPDFBase64 = async (data: any): Promise<string> => {
    let docDef: any = { content: [] };
    docDef = await FormFeedbackReport(data);

    return new Promise((resolve) => {
      pdfMake.createPdf(docDef).getBase64((result: any) => {
        resolve(result);
      });
    });
  };

  const medErrorCategoryOptions: any[] = state.masterOptions.medErrorCategory || [];

  const promiseArr = params.list.map(async (data: any) => {
    const categoryName = medErrorCategoryOptions.find(
      (option: any) => option.value === data.category
    )?.text;
    const message = MED_ERROR_CATEGORY.find(
      (value: string) => value.search(new RegExp(`^${categoryName}`, "g")) >= 0
    );

    const quarterDetail =
      typeof params.quarterDetail === "undefined"
        ? {
            quarter: data.quarter_no,
            year: data.quarter_year,
            startDate: data.quarter_start_date ? formatDate(moment(data.quarter_start_date)) : "",
            endDate: data.quarter_end_date ? formatDate(moment(data.quarter_end_date)) : "",
          }
        : params.quarterDetail;

    return createPDFBase64({
      ...data,
      category: message?.replace(" = ", ": "),
      staff: state.django?.user?.full_name || "",
      quarterDetail,
    });
  });

  const pdfBase64 = await Promise.all(promiseArr);

  const pdfDoc = await PDFDocument.create();

  for (const base64 of pdfBase64) {
    const doc = await PDFDocument.load(base64);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

    for (const page of copiedPages) {
      pdfDoc.addPage(page);
    }
  }

  const base64Data = await pdfDoc.saveAsBase64();

  const blob = base64toBlob(`data:application/pdf;base64,${base64Data}`);

  const bloburl = URL.createObjectURL(blob);
  window.open(bloburl);

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

/* ------------------------------------------------------ */

/*                  Handle SaveMedError;                  */

/* ------------------------------------------------------ */
const HandleSave: Handler<Params<"edit">> = async (controller, params) => {
  const state = controller.getState();
  const data = params.data || {};

  let errors: string[] = [];
  const requiredField = ["type", "type_detail", "stakeholder"] as const;

  const errorMessage = {
    corrective: "แจ้งไปยัง",
    corrective_user: "เจ้าหน้าที่ที่ต้องแก้ไข",
    type: "ประเภท",
    type_detail: "ลักษณะ",
    stakeholder: "แพทย์ผู้เกี่ยวข้อง",
  } as any;

  if (!data.editor_by) {
    errors.push("corrective");
  } else if (data.editor_by === "STAFF" && !data.corrective) {
    errors.push("corrective_user");
  }

  errors.push(
    ...requiredField.filter((field) =>
      Array.isArray(data[field]) ? data[field].length === 0 : !data[field]
    )
  );

  if (errors.length > 0) {
    params.callback?.("error", {
      กรุณากรอกข้อมูลที่จำเป็น: errors.map((error) => errorMessage[error]),
    });

    controller.setState({
      MedErrorListSequence: {
        ...state.MedErrorListSequence,
        sequenceIndex: "EditMedError",
      },
    });

    return;
  }

  const [_, error] = await CreateUpdateMedicationError(controller, {
    data,
    isActionComplete: params.isActionComplete,
  });

  if (error) {
    errors = Object.keys(error);

    controller.setState({
      MedErrorListSequence: {
        ...state.MedErrorListSequence,
        sequenceIndex: "EditMedError",
      },
    });
  }

  params.callback?.(error ? "error" : "success", {
    กรุณากรอกข้อมูลที่จำเป็น: errors.map((error) => errorMessage[error] || error),
  });
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetMedicationErrorList: Handler = (controller, params) => {
  const state = controller.getState();

  const urlParams: any = {};
  const data = params.filter || {};

  if (data.division && data.division !== "ALL") {
    urlParams.order_division = data.division;
  }
  if (data.status?.length) {
    urlParams.status = data.status;
  }
  if (data.start_date) {
    urlParams.start_date = data.start_date;
  }
  if (data.end_date) {
    urlParams.end_date = data.end_date;
  }
  if (data.type?.length) {
    urlParams.type = data.type;
  }
  if (data.stakeholder) {
    urlParams.stakeholder = data.stakeholder;
  }
  if (params.byCorrective) {
    urlParams.corrective_division = controller.data.division;
    urlParams.corrective_user = state.django.user.id;
  }

  return MedicationErrorList.list({
    apiToken: controller.apiToken,
    params: {
      ...urlParams,
      offset: 0,
      limit: 40,
    },
  });
};

const GetMasterMedErrorDetail: Handler<
  {
    isOrderItem?: boolean;
    id?: number;
  },
  Promise<{
    user: any[];
    type: any[];
    typeDetail: any[];
    cause: any[];
    detail: Partial<MedicationErrorSerializer>;
    causeDetailOptions: any[];
  }>
> = async (controller, params) => {
  const MedErrorDetailAPI = params.isOrderItem ? MedicationErrorDetail : MedicationErrorDetailM;

  const getDetail = await MedErrorDetailAPI.retrieve({
    apiToken: controller.apiToken,
    pk: params.id,
  });

  const detail: Partial<MedicationErrorSerializer> = getDetail[0] || {}; //รายการที่คลาดเคลื่อน

  const cause = (detail.cause || "") as string;
  const causeDetail = (detail.cause_detail || "") as string;

  const causes = cause ? cause.split(",") : [];
  const causeDetails = causeDetail ? causeDetail.split(",") : [];

  let getUserList: any = [];

  if (detail.stakeholder) {
    getUserList = UserList.list({
      apiToken: controller.apiToken,
      params: {
        pk: detail.stakeholder,
      },
    }); //ผู้รับผิดชอบ
  }

  const getTypeList = MedicationErrorTypeList.list({
    apiToken: controller.apiToken,
  }); //ประเภท

  const getTypeDetailList = MedicationErrorTypeDetailList.list({
    apiToken: controller.apiToken,
    params: { error_type: detail.type },
  }); //ลักษณะ

  const getCauseList = MedicationErrorCauseList.list({
    apiToken: controller.apiToken,
  }); //สาเหตุ

  const [[userList], [typeList], [typeDetailList], [causeList]] = await Promise.all([
    getUserList,
    getTypeList,
    getTypeDetailList,
    getCauseList,
  ]);

  const causeItems = causeList?.items || [];

  const causeDetailOptions = await GetCauseDetailOptions(controller, {
    cause: causes,
    causeList: causeItems,
    options: causeDetails.map((value: any) => ({
      key: value,
      value,
      text: value,
    })),
  });

  let corrective = "";

  if (detail.corrective_user) {
    corrective = "STAFF";
  } else if (detail.corrective_division) {
    corrective = "CLINIC";
  }

  detail.editor_by = corrective;

  return {
    user: userList?.items || [],
    type: typeList?.items || [],
    typeDetail: typeDetailList?.items || [],
    cause: causeList?.items || [],
    detail,
    causeDetailOptions,
  };
};

const CreateUpdateMedicationError: Handler<{
  data: Partial<MedicationErrorSerializer>;
  isActionComplete?: boolean;
}> = async (controller, params) => {
  const state = controller.getState();
  const data = params.data;

  const divisionOptions: any[] = state.masterOptions.division || [];

  const orderDivision =
    divisionOptions.find((option: any) => option.text === data.order_division)?.value || null;

  const corrective =
    data.editor_by === "CLINIC"
      ? { corrective_division: data.corrective, corrective_user: null }
      : { corrective_division: null, corrective_user: data.corrective };

  const cause = typeof data.cause === "string" ? [] : data.cause || [];
  const causeDetail = typeof data.cause_detail === "string" ? [] : data.cause_detail || [];
  const typeDetail = typeof data.type_detail === "string" ? [] : data.type_detail || [];

  const updateData = {
    ...data,
    cause: cause.join(","),
    cause_detail: causeDetail.join(","),
    type_detail: typeDetail.join(","),
    risk_date: beToAd(data.risk_date)?.format("YYYY-MM-DD"),
    order_division: orderDivision,
    ...corrective,
  };

  let result = null;
  let error = null;

  if (data.pk) {
    [result, error] = await MedicationErrorDetailM.update({
      apiToken: controller.apiToken,
      pk: data.id,
      data: {
        action: params.isActionComplete && data.solution_note ? "COMPLETE" : "EDIT",
        ...updateData,
      },
    });
  } else {
    [result, error] = await MedicationErrorList.create({
      apiToken: controller.apiToken,
      data: { action: "NOTE", ...updateData },
    });
  }

  return [result, error];
};

const GetCauseDetailOptions: Handler<{
  causeList: any[];
  cause: string[];
  options: any[];
}> = async (controller, params) => {
  const errorCause = params.cause.map(
    (value) => params.causeList.find((item) => item.name === value)?.id
  );

  const [causeDetailList] = await MedicationErrorCauseDetailList.list({
    apiToken: controller.apiToken,
    params: { error_cause: errorCause.join(",") },
  }); //สาเหตุ

  const options = mapOptions(causeDetailList?.items || [], "name");

  return [
    ...new Map([...options, ...params.options].map((item) => [item["value"], item])).values(),
  ];
};
