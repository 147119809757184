import WasmController from "react-lib/frameworks/WasmController";
// api
import AdverseReactionAdversaryList from "issara-sdk/apis/AdverseReactionAdversaryList_apps_ADR";
import AdverseReactionAdversaryPipelineView from "issara-sdk/apis/AdverseReactionAdversaryPipelineView_apps_ADR";
import PatientDetailViewByHn from "issara-sdk/apis/PatientDetailViewByHn_apps_REG";

import { adToBe } from "react-lib/utils/dateUtils";
import moment from "moment";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  UnverifiedAllergySequence?: any;
};

export const StateInitial: State = {
  UnverifiedAllergySequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "SelectEncounter"; params: any }
  | { message: "SetPatient"; params: any };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

const toDate = moment();

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.UnverifiedAllergySequence) return;

  controller.setState({
    UnverifiedAllergySequence: {
      ...state.UnverifiedAllergySequence,
      sequenceIndex: "Action",
      unverifiedAllergyFilter: {
        startDate: adToBe(toDate.format("DD/MM/YYYY")),
        endDate: adToBe(toDate.format("DD/MM/YYYY")),
      },
    },
  });
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params.action === "search") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let unverifiedAllergyFilter = state.UnverifiedAllergySequence?.unverifiedAllergyFilter;

    const [adversaryRes, adversaryErr, adversaryNet] =
      await AdverseReactionAdversaryPipelineView.get({
        apiToken: controller.apiToken,
        params: {
          create_date_start: unverifiedAllergyFilter?.startDate,
          create_date_end: unverifiedAllergyFilter?.endDate,
          patient: unverifiedAllergyFilter?.patient || "",
          adverse_reaction_status: "NOTED",
        },
        extra: {
          device: controller.data.device,
          division: params.division,
        },
      });

    if (adversaryRes) {
      controller.setState({
        UnverifiedAllergySequence: {
          ...state.UnverifiedAllergySequence,
          unverifiedAllergyList: adversaryRes?.items,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    }
  } else if (params.action === "selectAllergy") {
    const state = controller.getState();

    const [patient] = await PatientDetailViewByHn.get({
      apiToken: controller.apiToken,
      hn: params.selectData?.hn,
      extra: {
        division: controller.data.division,
      },
    });

    controller.handleEvent({
      message: "RunSequence",
      params: {
        sequence: "SetPatient",
        action: "select",
        patient: patient,
      },
    });
  }
};
